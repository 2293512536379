@import "main.css";
.b-bloc-divider {   height: 120px !important;   background-color: transparent !important;   border: none !important;    box-shadow: none !important;}

.mx-4.mb-3.logo-footer.mx-auto {

    width: 13rem !important;

}


.contact-container {
    margin-bottom: 2rem !important;
margin-top: 2rem !important;
}

h1.display-4.fw-bold.bandeauTitre.websitePageTitle-title.titles.text-color-seventh {
    color: #052659 !important;
}

.navbar-brand { height: 13vh !important; width: 13vw !important; }
.logo_nav { height: 11vh !important }

/*Cartes des services en domotique*/

@media (min-width: 992px) {
    #myGallery .zoom {
        width: 20% !important;
    }
}

#myGallery .zoom:hover {
transform: none !important;
pointer-events: none;
}

.banner {
flex-direction: row !important;
}
@media screen and (max-width: 1000px) {
.banner {
flex-direction: column !important;
}
}

/*----------LARGEUR DES IMAGES DANS LE BLOC PATCHWORK--------------*/

@media (min-width: 990px) {
.redirectsCards-div div.row-cols-1 > div.col {
width: 33.3333333333% !important;
}
}